<template>
    <div class="footer-container">
        <div class="content">
            <div class="left">
                <div>
                    <h3>关于我们</h3>
                    <ul>
                        <li><a href="/intro#intro">公司介绍</a></li>
                        <li><a href="/intro#honor">itrax荣誉</a></li>
                        <li><a href="/service#service">itrax服务</a></li>
                    </ul>
                </div>
                <div style="width:200px">
                    <h3>招聘咨询</h3>
                    <ul>
                        <li><i class="iconfont icon-phone"></i>TEL:010-87835788</li>
                        <li><i class="iconfont icon-email"></i>Email:hr@itrax.cn</li>
                        <li><i class="iconfont icon-location"></i>北京市朝阳区高碑店乡高井文化园路8号东亿国际传媒产业园区二期元君书苑F1号楼</li>
                    </ul>
                </div>
                <div>
                    <h3>商务咨询</h3>
                    <ul>
                        <li><i class="iconfont icon-phone"></i>TEL:010-87835788-8174/18614084650</li>
                        <li><i class="iconfont icon-email"></i>Email:wangyoulong@itrax.cn</li>
                    </ul>
                </div>
            </div>
            <nav>
                <a href="/">首页</a> | <a href="/intro">itrax</a> | <a href="/service">服务</a> | <a href="/customer">客户</a> | <a href="/case">案例</a> | <a href="/news">新闻</a> |
                <a href="/recruitment">招聘</a>
            </nav>
            <div class="qr">
                <img src="@/assets/qr.jpg" />
                <div>itrax官方公众号</div>
            </div>
        </div>
        <p>版权所有： 爱创营销与传播 www.itrax.cn 京ICP备15004759号</p>
    </div>
</template>

<script>
export default {
    name: "pc-footer",
    components: {},

    data() {
        return {};
    },
};
</script>
<style scoped>
@import url("../../../common/css/icon.css");
.footer-container .qr {
    display: flex;
    flex-direction: column;
    margin: 0 50px 0 0;
}

.footer-container .content nav {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}

.footer-container .left {
    display: flex;
}
.footer-container .left > div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.footer-container .left > div:last-child {
    align-items: flex-start;
}
.footer-container .left > div ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (max-width: 1440px) {
    .footer-container {
        min-width: 1440px;
    }
    .footer-container .content nav a {
        font-size: 12px;
        margin: 5px 15px;
    }
    .footer-container .left > div ul li {
        white-space: nowrap;
        margin: 8px 0;
        font-size: 12px;
        font-weight: normal;
        display: flex;
        align-items: center;
        line-height: 12px;
    }

    .footer-container .left > div ul li i::before {
        padding: 3px;
        border-radius: 50%;
        border: 1px #fff solid;
        font-size: 12px;
        margin-right: 15px;
    }

    .footer-container .left > div {
        margin-left: 60px;
    }
    .footer-container .left > div h3 {
        font-size: 13px;
        margin-bottom: 15px;
        margin-top: 10px;
    }
    .footer-container p {
        color: #fff;
        opacity: 0.4;
        font-size: 12px;
        margin: 10px 0 0 60px;
        text-align: left;
    }
    .footer-container {
        height: 170px;
        background: #0048b1;
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    .footer-container .content {
        display: flex;

        align-items: center;
    }
    .footer-container .qr img {
        margin-top: 20px;
        width: 94px;
        height: 94px;
    }
    .footer-container .qr div {
        font-size: 12px;
        text-align: center;
    }
}

@media only screen and (min-width: 1441px) {
    .footer-container {
        min-width: 1440px;
    }
    .footer-container .content nav a {
        font-size: 14px;
        margin: 5px 15px;
    }
    .footer-container .left > div ul li {
        margin: 10px 0;
        font-size: 14px;
        font-weight: normal;
        display: flex;
        align-items: center;
        white-space: nowrap;
        line-height: 14px;
    }
    .footer-container .left > div ul li i::before {
        padding: 3px;
        border-radius: 50%;
        border: 1px #fff solid;
        font-size: 14px;
        line-height: 14px;
        margin-right: 15px;
    }

    .footer-container .left > div {
        margin-left: 60px;
    }
    .footer-container .left > div h3 {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .footer-container p {
        color: #fff;
        opacity: 0.4;
        font-size: 14px;
        margin: 10px 0 0 60px;
        text-align: left;
    }
    .footer-container {
        height: 250px;
        background: #0048b1;
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    .footer-container .content {
        display: flex;
        margin-top: 20px;
        align-items: flex-end;
    }
    .footer-container .qr img {
        margin-top: 20px;
        width: 124px;
        height: 124px;
    }
    .footer-container .qr div {
        font-size: 14px;
        text-align: center;
    }
}
</style>
