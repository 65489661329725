<template>
    <div class="nav-container">
        <img src="@/assets/logo.png" class="logo" />
        <nav>
            <router-link to="/">首页<br /><label>HOME</label></router-link>
            <router-link to="/intro">itrax<br /><label>INTRODUCTION</label></router-link>
            <router-link to="/service">服务<br /><label>SERVICE</label></router-link>
            <router-link to="/customer">客户<br /><label>CUSTOMER</label></router-link>
            <router-link to="/case">案例<br /><label>CASE</label></router-link>
            <router-link to="/news">新闻<br /><label>NEWS</label></router-link>
            <router-link to="/recruitment">招聘<br /><label>RECRUITMENT</label></router-link>
        </nav>
    </div>
</template>

<script>
export default {
    name: "pc-nav",
    components: {},

    data() {
        return {};
    },

    mounted() {},
    methods: {},
};
</script>
<style scoped>
@import url("../../../common/css/icon.css");

.nav-container nav a:hover::before {
    width: 100%;
    left: 0;
    background-color: #fff !important;
}

.nav-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px 0 0 0;
}

.nav-container nav a::before {
    content: "";
    width: 0;
    position: absolute;
    height: 3px;
    bottom: 0;
    transition: all 0.4s;
    text-align: center;
    left: 50%;
}
.nav-container nav a label {
    margin-top: 5px;
    cursor: pointer;
    font-size: 14px;
}

.nav-container nav .router-link-exact-active::before {
    background-color: #fff !important;
    width: 100%;
    left: 0;
}

@media only screen and (max-width: 1440px) {
    .nav-container .logo {
        margin-right: 50px;
        width: 100px;
    }
    .nav-container nav {
        display: flex;
        align-items: center;
    }
    .nav-container nav a {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        font-size: 16px;
        margin: 0 30px;
        position: relative;
        padding-bottom: 7px;
        cursor: pointer;
        text-align: center;
    }
}

@media only screen and (min-width: 1441px) {
    .nav-container .logo {
        margin-right: 100px;
        width: 100px;
    }
    .nav-container nav {
        display: flex;
        align-items: center;
    }
    .nav-container nav a {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        font-size: 16px;
        margin: 0 30px;
        position: relative;
        padding-bottom: 7px;
        cursor: pointer;
        text-align: center;
    }
}
</style>
