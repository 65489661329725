<template>
    <div id="app">
        <!-- <transition name="fade"> -->
        <router-view />
        <!-- </transition> -->

        <vue-ui-loading v-if="showLoading" :msg="msg"></vue-ui-loading>
    </div>
</template>
<script>
import Vue from "vue";

export default {
    data() {
        return {
            showLoading: false,
            dialog: {},
            msg: "加载中...",
            showSubNav: false,
        };
    },
    components: {},
    beforeCreate() {
        Vue.prototype.$showLoading = (_msg) => {
            if (_msg) this.msg = _msg;
            this.showLoading = true;
        };
        Vue.prototype.$hideLoading = () => {
            this.showLoading = false;
        };
        Vue.prototype.$alert = (arg) => {
            this.dialog = arg;
        };
        this.$http.defaults({
            $400: (err) => {
                this.$alert({
                    title: "提示",
                    content: err.response.data ? err.response.data : err.response.statusText,
                });
            },
            $finally: () => {
                this.$hideLoading();
            },
        });
    },
    mounted() {
        if (this.$route.params.column) this.columnId = this.$route.params.column;
    },
    watch: {
        $route(to) {
            this.columnId = to.params.column;
        },
    },
    methods: {
        handelMenu(idx) {
            this.navIdx = idx;
        },
        handleNav() {
            this.showSubNav = !this.showSubNav;
        },
    },
};
</script>

<style>
html,
body {
    padding: 0;
    margin: 0;
    color: #fff;
    min-height: 100% !important;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* transform: scale(); */
}
body {
    background: #fff;
    font-size: 14px;
}
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #fff;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

img {
    vertical-align: middle;
    max-width: 100%;
}

.fade-enter-active {
    animation: fade-in 0.5s ease;
}
.fade-leave-active {
    animation: fade-in 0.5s ease reverse;
}
@keyframes fade-in {
    0% {
        /* transform: scale(0); */
        opacity: 0.5;
    }
    100% {
        /* transform: scale(1); */
        opacity: 1;
    }
}
</style>
