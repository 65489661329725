<template>
    <div class="container home">
        <div class="page-pagination"></div>
        <swiper :options="pageSwiperOption" ref="pageSwiper" :delete-instance-on-destroy="true" :cleanup-styles-on-destroy="true" @slideChangeTransitionEnd="slideChangeTransitionEnd" :auto-update="true" :auto-destroy="true">
            <swiper-slide v-if="showVideo">
                <div class="col-wrap section video " @click="moveNextPage(1)">
                    <video ref="homeVideo" id="homeVideo" muted autoplay src="@/assets/pc/home/homepage.mp4" @ended="playEnd"></video>
                    <div class="view-down">
                        <i class="iconfont icon-down" @click="moveNextPage(1)"></i>
                    </div>
                    <div class="mask"></div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="col-wrap banner section " :class="(showVideo && pageIndex == 1) || (!showVideo && pageIndex == 0) ? 'bg-animation' : ''">
                    <div class="wrap-content">
                        <Nav></Nav>
                        <div class="content">
                            <div class="view-more">
                                <div>
                                    <h3>提供端到端的 数字化与数据化</h3>
                                    <h1>营销与传播全价值链整合营销服务商</h1>
                                    <p>
                                        End-to-end Service Provider for Digital Marketing and Full-value-chain Integrated Marketing
                                    </p>
                                    <a href="/service">了解更多</a>
                                </div>
                            </div>
                            <div class="view-down">
                                <i class="iconfont icon-down" @click="moveNextPage(2)"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="col-wrap more-detail section cases" style="min-width:0;">
                    <div class="bg" :class="(showVideo && pageIndex == 2) || (!showVideo && pageIndex == 1) ? 'bg-animation2' : ''"></div>
                    <div class="content">
                        <div v-for="item in cases" :key="item.id">
                            <a :href="'/case/detail?id=' + item.id">
                                <!-- <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,w_600,h_340'" /> -->
                                <img v-if="item.homeCover" :src="resourceBaseUrl + item.homeCover + '?x-oss-process=image/resize,w_800'" />
                                <img v-else :src="resourceBaseUrl + item.cover + '?x-oss-process=image/auto-orient,1/resize,m_pad,w_800,h_400'" />
                                <!-- <h2 class="line2">{{ item.title }}</h2>
                                <p v-html="item.desc"></p> -->
                            </a>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="col-wrap section we">
                    <div class="bg" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'bg-animation2' : ''"></div>
                    <div class="wrap-content">
                        <div class="tit">
                            <h3>我们是谁</h3>
                            <p>数字营销时代的品牌同行者</p>
                            <p>Brand Explorer in the Age of Digital Marketing</p>
                        </div>
                        <div class="chart">
                            <div class="mask" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'chart-expand' : ''">
                                <img src="@/assets/pc/home/we-content.png" />
                            </div>
                            <div class="mask" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'chart-expand' : ''">
                                <div class="wrap" v-if="configs">
                                    <div class="chart-item chart1">
                                        <div class="des">
                                            <div>
                                                <h1>{{ configs.duration }}</h1>
                                                年
                                            </div>
                                            <p>专业深耕</p>
                                        </div>
                                        <div class="line" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'chart1-anima' : ''" @animationstart="chartAnimaStart"></div>
                                    </div>

                                    <div class="chart-item chart2">
                                        <div class="line" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'chart2-anima' : ''"></div>
                                        <div class="des">
                                            <div>
                                                <h1>{{ configs.employee }}</h1>
                                                +
                                            </div>
                                            <p>公司员工</p>
                                        </div>
                                    </div>

                                    <div class="chart-item chart3">
                                        <div class="des">
                                            <div>
                                                <h1>2017</h1>
                                                年
                                            </div>
                                            <p>加入浙文互联</p>
                                        </div>
                                        <div class="line" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'chart3-anima' : ''"></div>
                                    </div>

                                    <div class="chart-item chart4">
                                        <div class="line" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'chart4-anima' : ''"></div>
                                        <div class="des">
                                            <div>
                                                <h1>{{ configs.avgAge }}</h1>
                                                岁
                                            </div>
                                            <p>员工平均年龄</p>
                                        </div>
                                    </div>

                                    <div class="chart-item chart5">
                                        <div class="des">
                                            <div>
                                                第
                                                <h1>1</h1>
                                                梯队
                                            </div>
                                            <p>中国数字营销公司规模</p>
                                        </div>
                                        <div class="line" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'chart5-anima' : ''"></div>
                                    </div>
                                    <div class="chart-item chart6">
                                        <div class="line" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'chart6-anima' : ''"></div>
                                        <div class="des">
                                            <div>
                                                <h1>{{ configs.partner }}</h1>
                                                +
                                            </div>
                                            <p>世界500强企业合作伙伴</p>
                                        </div>
                                    </div>

                                    <div class="chart-item chart7">
                                        <div class="des">
                                            <div>
                                                <h1>{{ configs.carBusiness }}%</h1>
                                            </div>
                                            <p>汽车业务占比</p>
                                        </div>
                                        <div class="line" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'chart7-anima' : ''"></div>
                                    </div>

                                    <div class="chart-item chart8">
                                        <div class="line" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'chart8-anima' : ''"></div>
                                        <div class="des">
                                            <div>
                                                <h1>{{ configs.branchCompany }}</h1>
                                                地
                                            </div>
                                            <p v-html="configs.branchCompanyArea"></p>
                                        </div>
                                    </div>

                                    <div class="chart-item chart9">
                                        <div class="des">
                                            <div>
                                                <h1>{{ configs.businessScale }}亿</h1>
                                                +
                                            </div>
                                            <p>营业规模</p>
                                        </div>
                                        <div class="line" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'chart9-anima' : ''"></div>
                                    </div>

                                    <div class="chart-item chart10">
                                        <div class="line" :class="(showVideo && pageIndex == 3) || (!showVideo && pageIndex == 2) ? 'chart10-anima' : ''"></div>
                                        <div class="des">
                                            <div>
                                                <h1>{{ configs.socailScale }}%</h1>
                                            </div>
                                            <p>
                                                Socail占比
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="col-wrap customer section" :class="(showVideo && pageIndex == 4) || (!showVideo && pageIndex == 3) ? 'bg-animation' : ''">
                    <div class="content">
                        <div class="wrap-content"><img v-if="configs" :src="resourceBaseUrl + configs.pcCustomer + '?x-oss-process=image/resize,w_1280'" /> <a href="/customer" class="link-view-more">查看更多</a></div>
                        <Footer></Footer>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import settings from "@/settings";
export default {
    name: "PC-Home",
    components: { Nav, Footer },
    data() {
        return {
            showVideo: true,
            resourceBaseUrl: settings.resourceBaseUrl,
            pageIndex: 0,
            fullPageOptions: { licenseKey: "OPEN-SOURCE-GPLV3-LICENSE", scrollBar: false, navigation: true, controlArrows: false, afterLoad: this.pageAfterLoad },
            chart1Num: 0,
            pageSwiperOption: {
                speed: 500,
                resizeObserver: true,
                //notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
                direction: "vertical", //水平方向移动
                //grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
                //setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
                //autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
                //slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
                mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
                //mousewheelControl: false, //同上
                //height: window.innerHeight, // 高度设置，占满设备高度
                // resistanceRatio: 0.8, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
                // observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
                touchMoveStopPropagation: false,
                pagination: {
                    el: ".page-pagination",
                    clickable: true,
                },
                slidesPerView: "auto",
                touchRatio: 0.5,
                simulateTouch: false,

                // navigation: {
                //     nextEl: ".swiper-button-next",
                //     prevEl: ".swiper-button-prev",
                // },
            },
            configs: null,
            cases: [],
            isSafari: false,
            scale: 1,
        };
    },

    beforeRouteEnter: function(to, from, next) {
        //console.warn(to, from, this);
        // this.showVideo = false;
        next((vm) => {
            //console.warn(vm);
            if (from.path != "/") vm.showVideo = false;
            // vm.$forceUpdate();
        });
    },
    mounted() {
        this.loadConfigs();
        this.loadCase();
        window.addEventListener("mousewheel", this.enableSwiper, false) || window.addEventListener("DOMMouseScroll", this.enableSwiper2, false);
        if (navigator.userAgent.indexOf("Mac OS X") > 0) {
            this.isSafari = true;
        }
        //console.warn(document.documentElement.clientWidth);
        //alert(document.documentElement.clientWidth);
        // if (document.documentElement.clientWidth <= 1280) this.scale = 0.5;
    },

    methods: {
        playEnd() {
            console.warn("onend");
            if (this.pageIndex == 0) this.$refs["pageSwiper"].$swiper.slideNext();
        },
        loadConfigs() {
            this.$http.get("/api/index/data").then((res) => {
                this.configs = res.data;
            });
        },
        enableSwiper() {
            //console.warn("mousewheel", e.deltaY);
            // var swiper = this.$refs["pageSwiper"].$swiper;
            // swiper.mousewheel.enable();
        },
        enableSwiper2() {
            // console.warn("DOMMouseScroll", e.deltaY);
            // var swiper = this.$refs["pageSwiper"].$swiper;
            // swiper.mousewheel.enable();
        },

        slideChangeTransitionEnd() {
            this.pageIndex = this.$refs["pageSwiper"].$swiper.activeIndex;
            this.$forceUpdate();
            if (this.showVideo) {
                if (this.pageIndex != 0) {
                    this.$refs["homeVideo"].pause();
                } else {
                    this.$refs["homeVideo"].play();
                }
            }

            //if (!this.isSafari) return;
            this.$refs["pageSwiper"].$swiper.mousewheel.disable();
            var swiper = this.$refs["pageSwiper"].$swiper;
            setTimeout(() => {
                //swiper.slideTo(this.pageIndex);
                if (swiper && swiper.mousewheel) swiper.mousewheel.enable();
                //console.warn("enable=3");
            }, 1000);
        },
        chartAnimaStart() {
            //console.warn(1);
            // var self = this;
            // var _timer = setInterval(() => {
            //     if (self.chart1Num >= 17) {
            //         clearInterval(_timer);
            //         return;
            //     } else self.chart1Num++;
            // }, 1000 / 17);
        },

        moveNextPage(idx) {
            //this.$refs.fullpage.api.moveSectionDown();
            // this.$refs["pageSwiper"].$swiper.slideNext();
            this.$refs["pageSwiper"].$swiper.slideTo(this.showVideo ? idx : idx - 1);
        },
        // pageAfterLoad(prev, current) {
        //     this.pageIndex = current.index;
        // },
        loadCase() {
            this.$http.get("/api/case/recommend").then((res) => {
                this.cases = res.data;
            });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/pc.css");

.chart-item {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.chart-item .line {
    border-right: 1px #8abaff dashed;
}
.chart-item .des {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
}

.chart .mask {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    overflow: hidden;
}

.chart-expand {
    animation: expand-anim 3s linear forwards;
}

@keyframes expand-anim {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.link-view-more:hover {
    background: #0144b1;
    color: #fff;
}
.wrap-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.customer {
    background-image: url("~@/assets/pc/home/customer-bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
}
.customer .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1;
}
.customer .content .wrap-content {
    flex: 1;
}

.cases .bg {
    background: url("~@/assets/pc/home/case-bg.jpg") no-repeat center;
    background-size: cover;
}

.we {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}
.we .bg {
    background: url("~@/assets/pc/home/we-bg.jpg") no-repeat center;
    background-size: cover;
}

.we .wrap-content .tit {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
}
.we .wrap-content h3 {
    margin: 5px 0;
    font-size: 30px;
    font-weight: normal;
}
.we .wrap-content p {
    font-size: 20px;
    margin: 5px 0;
}

.home {
    height: 100%;
}
.banner {
    background: url("~@/assets/pc/home/banner-bg.jpg") no-repeat center bottom;
    background-size: 100% auto;
    background-position: center bottom;
    background-color: #0047c5;
}
.banner .wrap-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.banner .wrap-content .content {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.banner .wrap-content .content .view-more {
    flex: 1;
}

.banner .view-down {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.view-down i {
    font-size: 40px;
    animation: jump 2s ease infinite;
    cursor: pointer;
}

.video {
    position: relative;
}

.video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video .mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.video .view-down {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: 30px;
    margin-left: -25px;
    z-index: 3;
}
.col-wrap {
    position: relative;
}
.col-wrap .bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.scale-image {
    animation: scale-image 1s linear forwards;
}
@keyframes jump {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes scale-image {
    0% {
        transform: scale(0.7);
    }
    100% {
        transform: scale(1);
    }
}

.container .more-detail {
    display: flex;
    flex-wrap: wrap;
}

.view-more {
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.view-more div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.more-detail .content > div {
    overflow: hidden;
    position: relative;
    width: 47%;
    border-radius: 10px;
    text-align: left;
    margin: 20px 0;
}
.more-detail .content > div img {
    width: 100%;
}
/* .more-detail .content > div h2 {
    font-size: 16px;
    color: #333333;
    margin: 10px 15px;
    font-weight: normal;
}
.more-detail .content > div p {
    color: #666666;
    font-size: 12px;
    margin: 0 15px 20px 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
} */

@media only screen and (max-width: 1440px) {
    .more-detail .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 1280px;
        margin: auto auto;
    }

    .view-more h3 {
        font-size: 24px;
    }
    .view-more h1 {
        font-size: 30px;
        margin: 20px 0;
    }
    .view-more p {
        font-size: 20px;
        text-align: center;
    }
    .view-more a {
        background: #fff;
        width: 140px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1e4b94;
        font-size: 12px;
        font-weight: 600;
        border-radius: 28px;
        margin-top: 40px;
    }
    .customer a {
        color: #333333;
        width: 140px;
        height: 36px;
        border: 1px #333333 solid;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        margin-top: 100px;
        margin-bottom: 30px;
    }
    .customer .content .wrap-content img {
        width: 80%;
        margin: 30px;
    }

    .chart-item .des div {
        display: flex;
        align-items: flex-end;
        font-size: 13px;
        margin: 10px 0;
        line-height: 13px;
    }
    .chart-item .des div h1 {
        font-size: 35px;
        font-style: italic;
        line-height: 35px;
    }
    .chart-item .des p {
        margin: 5px 0 !important;
        font-size: 12px !important;
    }
    .chart {
        width: 900px;
        height: 388px;
        position: relative;
    }
    .chart .mask img {
        width: 900px;
        height: 368px;
        max-width: none;
    }

    .chart .mask .wrap {
        width: 100%;
        height: 566px;
        position: relative;
    }

    .col-wrap {
        height: 100%;
    }
    .chart1 {
        left: 58px;
        bottom: 358px;
    }
    .chart2 {
        top: 204px;
        left: 110px;
    }
    .chart3 {
        left: 188px;
        bottom: 370px;
    }
    .chart4 {
        top: 219px;
        left: 273px;
    }
    .chart5 {
        left: 346px;
        bottom: 378px;
    }

    .chart6 {
        top: 173px;
        left: 450px;
    }
    .chart7 {
        left: 557px;
        bottom: 387px;
    }
    .chart8 {
        top: 219px;
        left: 606px;
    }

    .chart9 {
        left: 721px;
        bottom: 363px;
    }

    .chart10 {
        top: 192px;
        left: 777px;
    }

    .chart1-anima {
        animation: chart1-kf 0.5s linear 0.2s forwards;
    }
    .chart2-anima {
        animation: chart2-kf 0.5s linear 0.4s forwards;
    }
    .chart3-anima {
        animation: chart3-kf 0.5s linear 0.6s forwards;
    }
    .chart4-anima {
        animation: chart4-kf 0.5s linear 0.8s forwards;
    }

    .chart5-anima {
        animation: chart5-kf 0.5s linear 1s forwards;
    }

    .chart6-anima {
        animation: chart6-kf 0.5s linear 1.3s forwards;
    }

    .chart7-anima {
        animation: chart7-kf 0.5s linear 1.5s forwards;
    }

    .chart8-anima {
        animation: chart4-kf 0.5s linear 2s forwards;
    }

    .chart9-anima {
        animation: chart9-kf 0.5s linear 2.4s forwards;
    }

    .chart10-anima {
        animation: chart10-kf 0.5s linear 2.7s forwards;
    }

    @keyframes chart1-kf {
        from {
            height: 0;
        }
        to {
            height: 100px;
        }
    }

    @keyframes chart2-kf {
        0% {
            height: 0;
        }
        100% {
            height: 82px;
        }
    }
    @keyframes chart3-kf {
        0% {
            height: 0;
        }
        100% {
            height: 80px;
        }
    }

    @keyframes chart4-kf {
        0% {
            height: 0;
        }
        100% {
            height: 68px;
        }
    }

    @keyframes chart5-kf {
        0% {
            height: 0;
        }
        100% {
            height: 78px;
        }
    }

    @keyframes chart6-kf {
        0% {
            height: 0;
        }
        100% {
            height: 115px;
        }
    }

    @keyframes chart7-kf {
        0% {
            height: 0;
        }
        100% {
            height: 72px;
        }
    }

    @keyframes chart9-kf {
        0% {
            height: 0;
        }
        100% {
            height: 95px;
        }
    }

    @keyframes chart10-kf {
        0% {
            height: 0;
        }
        100% {
            height: 96px;
        }
    }
}

@media only screen and (min-width: 1441px) {
    .more-detail .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 1440px;
        margin: auto auto;
    }
    .view-more h3 {
        font-size: 24px;
    }
    .view-more h1 {
        font-size: 30px;
        margin: 15px 0;
    }
    .view-more p {
        font-size: 20px;
        text-align: center;
    }
    .view-more a {
        background: #fff;
        width: 170px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1e4b94;
        font-size: 14px;
        font-weight: 600;
        border-radius: 28px;
        margin-top: 50px;
    }
    .customer a {
        color: #333333;
        width: 170px;
        height: 46px;
        border: 2px #333333 solid;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        margin-top: 100px;
    }
    .chart-item .des div {
        display: flex;
        align-items: flex-end;
        font-size: 15px;
        margin: 10px 0;
        line-height: 15px;
    }
    .chart-item .des div h1 {
        font-size: 55px;
        font-style: italic;
        line-height: 45px;
    }
    .chart-item .des p {
        margin: 15px 0 !important;
        font-size: 18px !important;
    }
    .chart {
        width: 1384px;
        height: 566px;
        position: relative;
    }
    .chart .mask img {
        width: 1384px;
        height: 566px;
        max-width: none;
    }

    .chart .mask .wrap {
        width: 100%;
        height: 566px;
        position: relative;
    }
    .col-wrap {
        height: 100%;
    }
    .chart1 {
        left: 89px;
        bottom: 245px;
    }
    .chart2 {
        top: 315px;
        left: 170px;
    }
    .chart3 {
        left: 294px;
        bottom: 264px;
    }
    .chart4 {
        top: 336px;
        left: 421px;
    }
    .chart5 {
        left: 533px;
        bottom: 276px;
    }

    .chart6 {
        top: 268px;
        left: 693px;
    }
    .chart7 {
        left: 856px;
        bottom: 290px;
    }
    .chart8 {
        top: 340px;
        left: 934px;
    }

    .chart9 {
        left: 1108px;
        bottom: 253px;
    }

    .chart10 {
        top: 294px;
        left: 1194px;
    }

    .chart1-anima {
        animation: chart1-kf 0.5s linear 0.2s forwards;
    }
    .chart2-anima {
        animation: chart2-kf 0.5s linear 0.4s forwards;
    }
    .chart3-anima {
        animation: chart3-kf 0.5s linear 0.6s forwards;
    }
    .chart4-anima {
        animation: chart4-kf 0.5s linear 0.8s forwards;
    }

    .chart5-anima {
        animation: chart5-kf 0.5s linear 1s forwards;
    }

    .chart6-anima {
        animation: chart6-kf 0.5s linear 1.3s forwards;
    }

    .chart7-anima {
        animation: chart7-kf 0.5s linear 1.5s forwards;
    }

    .chart8-anima {
        animation: chart4-kf 0.5s linear 2s forwards;
    }

    .chart9-anima {
        animation: chart9-kf 0.5s linear 2.4s forwards;
    }

    .chart10-anima {
        animation: chart10-kf 0.5s linear 2.7s forwards;
    }

    @keyframes chart1-kf {
        from {
            height: 0;
        }
        to {
            height: 200px;
        }
    }

    @keyframes chart2-kf {
        0% {
            height: 0;
        }
        100% {
            height: 102px;
        }
    }
    @keyframes chart3-kf {
        0% {
            height: 0;
        }
        100% {
            height: 180px;
        }
    }

    @keyframes chart4-kf {
        0% {
            height: 0;
        }
        100% {
            height: 81px;
        }
    }

    @keyframes chart5-kf {
        0% {
            height: 0;
        }
        100% {
            height: 173px;
        }
    }

    @keyframes chart6-kf {
        0% {
            height: 0;
        }
        100% {
            height: 149px;
        }
    }

    @keyframes chart7-kf {
        0% {
            height: 0;
        }
        100% {
            height: 152px;
        }
    }

    @keyframes chart9-kf {
        0% {
            height: 0;
        }
        100% {
            height: 191px;
        }
    }

    @keyframes chart10-kf {
        0% {
            height: 0;
        }
        100% {
            height: 126px;
        }
    }
}
.swiper-container {
    overflow: hidden;
}

@import url("../../../common/css/pc-home.css");
</style>
