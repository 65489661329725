import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@babel/polyfill'

import request from "@g1100100/rest.request"
import vueUI from "@g1100100/vue-ui"

import "@g1100100/vue-ui/lib/vue-ui.css"

// import 'fullpage.js/vendors/scrolloverflow';
// import VueFullPage from 'vue-fullpage.js';

import VueAwesomeSwiper from 'vue-awesome-swiper'
import "swiper/css/swiper.css"
Vue.use(VueAwesomeSwiper, {})

// Vue.use(VueFullPage);
Vue.prototype.$http = request;
Vue.use(vueUI);
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')