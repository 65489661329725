import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/pc/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "爱创营销与传播",
        },
    },
    {
        path: "/m",
        name: "MobileHome",
        component: function() {
            return import("../views/mobile/Home.vue");
        },
        meta: {
            title: "爱创营销与传播",
        },
    },
    {
        path: "/intro",
        name: "PC-Intro",
        component: function() {
            return import("../views/pc/intro.vue");
        },
        meta: {
            title: "关于itrax",
        },
    },
    {
        path: "/m/intro",
        name: "Mobile-Intro",
        component: function() {
            return import("../views/mobile/intro.vue");
        },
        meta: {
            title: "关于itrax",
        },
    },
    {
        path: "/service",
        name: "PC-Service",
        component: function() {
            return import("../views/pc/service.vue");
        },
        meta: {
            title: "服务",
        },
    },
    {
        path: "/m/service",
        name: "Mobile-Service",
        component: function() {
            return import("../views/mobile/service.vue");
        },
        meta: {
            title: "itrax服务",
        },
    },
    {
        path: "/customer",
        name: "PC-customer",
        component: function() {
            return import("../views/pc/customer.vue");
        },
        meta: {
            title: "客户",
        },
    },
    {
        path: "/m/customer",
        name: "Mobile-Customer",
        component: function() {
            return import("../views/mobile/customer.vue");
        },
        meta: {
            title: "客户",
        },
    },
    {
        path: "/case",
        name: "PC-Case",
        component: function() {
            return import("../views/pc/case.vue");
        },
        meta: {
            title: "案例",
        },
    },
    {
        path: "/case/detail",
        name: "PC-Case-Detail",
        component: function() {
            return import("../views/pc/caseDetail.vue");
        },
        meta: {
            title: "案例详情",
        },
    },
    {
        path: "/m/case",
        name: "Mobile-Case",
        component: function() {
            return import("../views/mobile/case.vue");
        },
        meta: {
            title: "案例",
        },
    },
    {
        path: "/m/case/detail",
        name: "Mobile-Case-Detail",
        component: function() {
            return import("../views/mobile/caseDetail.vue");
        },
        meta: {
            title: "案例详情",
        },
    },
    {
        path: "/news",
        name: "PC-News",
        component: function() {
            return import("../views/pc/news.vue");
        },
        meta: {
            title: "新闻",
        },
    },
    {
        path: "/news/detail",
        name: "PC-News-Detail",
        component: function() {
            return import("../views/pc/newsDetail.vue");
        },
        meta: {
            title: "新闻详情",
        },
    },
    {
        path: "/m/news",
        name: "Mobile-News",
        component: function() {
            return import("../views/mobile/news.vue");
        },
        meta: {
            title: "新闻",
        },
    },
    {
        path: "/m/news/detail",
        name: "Mobile-News-Detail",
        component: function() {
            return import("../views/mobile/newsDetail.vue");
        },
        meta: {
            title: "新闻详情",
        },
    },
    {
        path: "/recruitment",
        name: "PC-Recruitment",
        component: function() {
            return import("../views/pc/recruitment.vue");
        },
        meta: {
            title: "招聘",
        },
    },
    {
        path: "/m/recruitment",
        name: "Mobile-Recruitment",
        component: function() {
            return import("../views/mobile/recruitment.vue");
        },
        meta: {
            title: "招聘",
        },
    },
    {
        path: "/recruitment/detail",
        name: "PC-Recruitment-Detail",
        component: function() {
            return import("../views/pc/recruitmentDetail.vue");
        },
        meta: {
            title: "招聘",
        },
    },
    {
        path: "/:column",
        name: "column",
        component: Home,
        meta: {
            title: "",
        },
    },
];
// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) {
        // savedPosition is only available for popstate navigations.
        return savedPosition;
    } else {
        const position = {};
        // new navigation.
        // scroll to anchor by returning the selector
        if (to.hash) {
            position.selector = to.hash;
        }
        // check if any matched route config has meta that requires scrolling to top
        if (to.matched.some((m) => m.meta.scrollToTop)) {
            // cords will be used if no selector is provided,
            // or if the selector didn't match any element.
            position.x = 0;
            position.y = 0;
        }
        // if the returned position is falsy or an empty object,
        // will retain current scroll position.
        return position;
    }
};
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior,
});
router.beforeEach((to, from, next) => {
    var w = document.documentElement.clientWidth;
    if (w <= 600 && to.fullPath.substr(0, 2) != "/m") {
        router.replace({ path: "/m" + to.path, params: to.params, query: to.query });
        return;
    }
    //console.warn(to);
    if (w > 600 && to.fullPath.substr(0, 2) == "/m") {
        router.replace({ path: to.path.substr(2), params: to.params, query: to.query });
        return;
    }

    if (w > 600 && to.path == "/") {
        document.querySelector("#app").style.overflow = "hidden";
        document.querySelector("#app").style.height = "100%";
    } else {
        document.querySelector("#app").style.overflow = "";
        document.querySelector("#app").style.height = "";
    }
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

export default router;
